<template>
    <div v-loading="isLoadingChart">
        <div>
            <div class="d-flex align-items-center align-content-center">
                 <div class="flex-fill mr-2">
                    <el-radio-group v-model="packageSelected" size="small" class="mr-3 d-none d-md-block"
                                    @change="getSpentOnPackageByMonth">
                        <el-radio-button label="all" class="mb-0">All</el-radio-button>
                        <el-radio-button :key="packageVal.id" v-for="packageVal in packageList" :label="packageVal.id" class="mb-0">
                            {{packageVal.name}} ({{packageVal.description}})
                        </el-radio-button>
                    </el-radio-group>

                     <select class="form-control form-control-sm mr-2 d-block d-md-none w-100"
                            style="width: 8rem;" v-model="packageSelected"
                            @change="getSpentOnPackageByMonth">                         
                        <option value="all">All package</option>
                        <option :key="packageVal.id" v-for="packageVal in packageList" :value="packageVal.id">
                            {{packageVal.name}} ({{packageVal.description}})
                        </option>
                    </select>
                </div>
                <div class="d-flex align-items-center">
                    <select class="form-control form-control-sm mr-2"
                            style="width: 8rem;" v-model="numberOfMonth"
                            @change="getSpentOnPackageByMonth">
                        <option value="6">Last 6 months</option>
                        <option value="8">Last 8 months</option>
                        <option value="10">Last 10 months</option>
                        <option value="12">Last 12 months</option>
                    </select>
                    <button class="btn btn-sm btn-primary" @click="getSpentOnPackageByMonth">
                        <i class="fas fa-sync-alt mr-md-2"></i> <span class="d-none d-md-inline-block">Refresh</span>
                    </button>
                </div>
            </div>
        </div>
        <highcharts class="mt-4 mr-1" :options="spentOnPackageByMonth.options"></highcharts>
        <div class="text-center">
            <div>
                <el-radio-group v-model="typeOfChart" size="medium" class="mr-3"
                                @change="onChangeTypeOfChart">
                    <el-radio-button label="column" class="mb-0"><i class="fas fa-chart-bar"></i> Column</el-radio-button>
                    <el-radio-button label="spline" class="mb-0"><i class="fas fa-chart-line"></i> Spline</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <hr/>
    </div>
</template>
<script>
    import chartDataApi from '@/api/common/chartData';
    import { 
        areaSplineChart,
        colorSun, colorSat, colorNormal
    } from "@/constant/dashboardChartOptions";
    import { Chart } from 'highcharts-vue';
    var jsonToPivotjson = require("json-to-pivot-json");
    import moment from "moment";
    var numeral = require('numeral');
    export default {
        components: {
            highcharts: Chart
        },
        data() {
            return {
                isLoadingChart: false,
                numberOfMonth: 6,
                packageList: [],
                packageSelected : "all",
                typeOfChart : "column",
                spentOnPackageByMonth: {
                    options: {
                        chart: {
                            type: 'column'
                        },
                        title: {
		                    text: '',
	                    },
                        xAxis: {
                            categories: [],
                            crosshair: true
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: `Spent amount (${this.$pointNameShort})`
                            }
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                `<td style="padding:0"><b>{point.y:.4f} ${this.$pointNameShort}</b></td></tr>`,
                            footerFormat: '</table>',
                            shared: true,
                            useHTML: true
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
				                    enabled: true
			                    },
                                pointPadding: 0.2,
                                borderWidth: 0
                            },
                            spline: {
                                dataLabels: {
				                    enabled: true
			                    },
                                pointPadding: 0.2,
                                borderWidth: 0
                            }
                        },
                        series: []
                    },
                    tableData: []
                }
            };
        },
        mounted() {
            this.getSpentOnPackageByMonth();
        },
        methods: {
            getSpentOnPackageByMonth() {
                this.isLoadingChart = true;
                chartDataApi.getSpentOnPackageByMonth(this.numberOfMonth).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        let options = {
                            row: "MonthExport",
                            column: "VmPackageId",
                            value: "TotalSpentAmount"
                        };
                        let output = jsonToPivotjson(response.data.data, options);
                        let packageIdList = response.data.data.map(x => x.VmPackageId).filter((value, index, self) => {
                            return self.indexOf(value) === index;
                        });

                        this.packageList = packageIdList.map(p => ({
                            "id" : p,
                            "name": ((this.$store.state.gpuNodeName[p]) ? `${this.$store.state.gpuNodeName[p].name}` : p),
                            "description": ((this.$store.state.gpuNodeName[p]) ? `${this.$store.state.gpuNodeName[p].description}` : p),
                        }));

                        this.spentOnPackageByMonth.options.xAxis.categories = output.map(x => x.MonthExport);
                        this.spentOnPackageByMonth.options.series = packageIdList.filter(x => (this.packageSelected == "all" || this.packageSelected === x)).map(p => {
                            return {
                                name: ((this.$store.state.gpuNodeName[p]) ? `${this.$store.state.gpuNodeName[p].name}` : p),
                                data: output.map(x => {
                                    return {
                                        y: x[p],
                                    }
                                })
                            }
                        });
                        this.isLoadingChart = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoadingChart = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoadingChart = false;
                });               
            },
            onChangeTypeOfChart() {
                this.spentOnPackageByMonth.options.chart.type = this.typeOfChart;
            }
        }
    }
</script>